import useHandle3dsTxnId from '@root/hooks/useHandle3dsTxnId';

import BrTemplateWithSidebar from '@components/templates/BrTepmplateWithSidebar';

import ImtuCompletedOrder from '@components/modules/ImtuCompletedOrder';

import { IMTU_3DS_PENDING_TXN_ID_STORE_KEY } from '@services/auth/constants';

const ImtuCompletedOrderPage = () => {
  const { txnId } = useHandle3dsTxnId({ storeKey: IMTU_3DS_PENDING_TXN_ID_STORE_KEY });

  return (
    <BrTemplateWithSidebar>
      {txnId && <ImtuCompletedOrder txnId={txnId} />}
    </BrTemplateWithSidebar>
  );
};

export default ImtuCompletedOrderPage;
