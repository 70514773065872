import { normalizeStringCompound } from '@utils/string';

interface Props {
  className?: string;
}

const Circle = (props: Props) => {
  const { className } = props;

  return (
    <div
      className={normalizeStringCompound([
        'w-[48px] h-[48px] top-1/2 -translate-y-1/2 rounded-full bg-color/background',
        className,
      ])}
    />
  );
};

export default Circle;
