import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useFormikContext, Form as FormikForm } from 'formik';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { Assign, ObjectShape } from 'yup/lib/object';
import { AnyObject } from 'yup/lib/types';

import useOnClickOutside from '@root/hooks/useOnClickOutside';

import { getNormalizedCountryDialCode } from '@components/common/PhoneInput/helpers';
import BrAdvancedInput, { BrAdvancedInputRef } from '@components/common/BrAdvancedInput';

import CountryCode from '@root/interfaces/CountryCode';
import BrInputProps from '@root/interfaces/components/BrInput';
import { TestIdDataAttr } from '@root/interfaces/components/Common';

import { normalizeStringCompound } from '@utils/string';
import Yup from '@utils/validation/yup';

import { getPhoneMaskByCountryCode } from '@helpers/phone';

import { getIconCfg, ICON_CLEAR_CFG } from '../../helpers';

/* eslint-disable @typescript-eslint/indent */
type FormValidationSchema = Yup.ObjectSchema<
  Assign<
    ObjectShape,
    {
      phone: Yup.StringSchema<string, AnyObject, string>;
    }
  >
>;
/* eslint-disable @typescript-eslint/indent */
interface Props extends TestIdDataAttr {
  phone?: string;
  countryCode: CountryCode;
  className?: string;
  onSubmit?(value: string): void;
  onChange?(params: { phoneNumber: string }): void;
  onValidation?(res: { isValid: boolean; errorText?: string }): void;
  validationSchema?: FormValidationSchema;
}

const CLEARED_VALUE = '';
const MASK_PREFIX = '+';

const Form = forwardRef<{ submit: () => void }, Props>((props, ref) => {
  const {
    countryCode,
    className,
    dataTestId,
    validationSchema,
    onChange,
    onValidation,
  } = props;

  const formik = useFormikContext<{
    phone: string;
  }>();
  const { t } = useTranslation();

  const [isInitialRender, setIsInitialRender] = useState(true);
  const [isBlurred, setIsBlurred] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isPhoneInputTouched, setIsPhoneInputTouched] = useState<boolean | undefined>();

  const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
  const brAdvancedInputRef = useRef<BrAdvancedInputRef>(null);

  useOnClickOutside(() => {
    setIsBlurred(true);
  }, brAdvancedInputRef.current?.inputWrapperRef || { current: undefined });

  useImperativeHandle(ref, () => {
    return {
      submit: () => {
        hiddenSubmitButtonRef.current?.click();
      },
    };
  });

  const phoneCode = t(getNormalizedCountryDialCode(countryCode));

  const isFormHasErrors = Boolean(formik.errors.phone) || !isFormValid;

  const iconRightCfg =
    (formik.isValid && isFormValid) || isInitialRender
      ? undefined
      : getIconCfg(formik.values.phone, isFormHasErrors, isBlurred);

  /* eslint-disable @typescript-eslint/indent */
  const handleOnIconClickRight =
    iconRightCfg?.iconName === ICON_CLEAR_CFG.iconName &&
    !isBlurred &&
    formik.values.phone !== CLEARED_VALUE &&
    formik.values.phone !== MASK_PREFIX
      ? () => {
          formik.resetForm({
            values: { phone: CLEARED_VALUE },
            errors: { phone: 'empty' },
          });
          brAdvancedInputRef.current?.inputRef.current?.focus();
        }
      : undefined;
  /* eslint-enable @typescript-eslint/indent */

  const handleOnFocus = () => {
    setIsBlurred(false);
    setIsInitialRender(false);
    setIsPhoneInputTouched(true);
    validationSchema
      ?.validate({ phone: formik.values.phone })
      .catch(() => setIsFormValid(false));
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setIsInitialRender(false);
    formik.setFieldValue('phone', newValue);

    // in case user started typing incorrect dial code
    if (newValue <= phoneCode && !phoneCode.startsWith(newValue)) {
      formik.setTouched({ phone: true });
    }

    onChange?.({
      phoneNumber: newValue,
    });
  };

  const hasSuccess =
    (!isInitialRender && formik.isValid && isFormValid && isPhoneInputTouched) ||
    (isInitialRender && formik.isValid);
  Boolean(formik.values.phone.replaceAll('+', ''));

  const hasError = Boolean(formik.errors.phone) && Boolean(formik.touched.phone);

  useEffect(() => {
    setIsFormValid(true);
  }, [formik.isValid]);

  useEffect(() => {
    setIsPhoneInputTouched(formik.touched?.phone);
  }, [formik.touched.phone]);

  useEffect(() => {
    onValidation?.({
      isValid: !formik.errors.phone,
      errorText: formik.errors.phone,
    });
  }, [formik.errors.phone, onValidation]);

  const phoneNumberInputMask = `+${getPhoneMaskByCountryCode(countryCode)}`;

  useLayoutEffect(() => {
    setIsInitialRender(true);
  }, [countryCode]);

  return (
    <FormikForm className={normalizeStringCompound(['w-full', className])}>
      <InputMask
        mask={phoneNumberInputMask}
        maskChar=""
        inputMode="numeric"
        prefix={MASK_PREFIX}
        onFocus={handleOnFocus}
        {...formik.getFieldProps('phone')}
        onChange={handleOnChange}
      >
        {(inputProps: BrInputProps) => (
          <BrAdvancedInput
            {...inputProps}
            placeholder={t('Enter the phone number')}
            ref={brAdvancedInputRef}
            hasSuccess={hasSuccess}
            hasError={hasError}
            errorText={formik.errors.phone}
            errorTextClassNames="lg:whitespace-nowrap"
            onWhat="surface"
            dataTestId={dataTestId}
            iconRight={iconRightCfg?.iconName}
            iconRightClassNames={iconRightCfg?.iconClassNames}
            onIconClickRight={handleOnIconClickRight}
          />
        )}
      </InputMask>
      {/* Hidden submit button - submit triggers from the outside of the form */}
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button ref={hiddenSubmitButtonRef} type="submit" className="hidden" />
    </FormikForm>
  );
});

export default Form;
