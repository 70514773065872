import React, { FC } from 'react';
import { Toast } from 'react-hot-toast';

import { BrToastType, brToastTypes } from '@services/toasts';

import { normalizeStringCompound } from '@utils/string';

const Toaster: FC<React.PropsWithChildren<Toast>> = (props) => {
  const { message, className } = props;

  const toastType: BrToastType = className ? brToastTypes[className] : 'info';

  const darkToastClassNames =
    'flex text-center items-center justify-center text-color/white bg-color/black bg-opacity-70 animate-toast';

  switch (toastType) {
    case 'medium':
      return (
        <div
          className={normalizeStringCompound([
            darkToastClassNames,
            'text-body/caption/medium rounded-middle p-middle',
          ])}
        >
          {message}
        </div>
      );
    case 'large':
      return (
        <div
          className={normalizeStringCompound([
            darkToastClassNames,
            'text-body/primary/default rounded-[60px] px-xlarge py-default',
          ])}
        >
          {message}
        </div>
      );
    case 'small':
    default:
      return (
        <div
          className={normalizeStringCompound([
            darkToastClassNames,
            'text-body/caption-2/default rounded-small p-small whitespace-pre-line',
          ])}
        >
          {message}
        </div>
      );
  }
};

export default Toaster;
