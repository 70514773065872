import { useTranslation } from 'react-i18next';

import CountryCode from '@root/interfaces/CountryCode';

import { formatPhone } from '@helpers/phone';

import BrButton from '../BrButton';
import BrContacts from '../BrContacts';
import { CarrierInfo } from '../BrListItem';
import BrBadge from '../BrBadge';

interface Props {
  cmpVersion: 'with-photo' | 'non-photo';
  /* eslint-disable @typescript-eslint/indent */
  cmpState:
    | 'default'
    | 'non-contact'
    | 'subscription'
    | 'queued'
    | 'subscription-changed';
  /* eslint-disable @typescript-eslint/indent */
  phone: string;
  carrierCode?: string;
  orderDescription: string;
  carrierPromoText?: string;
  subscriptionIntervalText?: string;
  countryCode: CountryCode;
  onViewReceiptClick?(): void;
}

const BrCompletedOrder = (props: Props) => {
  const {
    cmpVersion,
    cmpState,
    phone,
    carrierCode,
    orderDescription,
    carrierPromoText,
    countryCode,
    onViewReceiptClick,
    subscriptionIntervalText,
  } = props;

  const { t } = useTranslation();

  const getTextByCmpState = () => {
    switch (true) {
      case cmpState === 'queued':
        return 'Processing'; // t('Processing')
      case Boolean(subscriptionIntervalText):
        return 'will receive a subscription to'; // t('will receive a subscription to')
      case cmpState === 'subscription-changed':
        return undefined;
      default:
        return 'will receive'; // t('will receive')
    }
  };

  const textByCmpState = getTextByCmpState();

  return (
    <div className="flex flex-col items-center space-y-middle">
      <div className="space-y-xsmall flex flex-col items-center">
        {cmpVersion === 'with-photo' && (
          <BrContacts cmpSize="large" cmpType="flag" countryCode={countryCode} />
        )}
        <div className="text-body/callout/default">{formatPhone(phone, countryCode)}</div>
        {textByCmpState && (
          <div className="text-body/callout/default text-center">{textByCmpState}</div>
        )}

        <div className="flex items-center space-x-xsmall flex-wrap justify-center">
          <CarrierInfo
            className="inline-flex"
            size="xSmall"
            name={orderDescription}
            carrierCode={carrierCode}
            countryCode={countryCode}
          />{' '}
          {carrierPromoText && (
            <BrBadge
              text={carrierPromoText}
              classType="warning"
              iconLeft="egift-solid"
              styleType="default"
            />
          )}
        </div>
      </div>
      <div className="flex flex-col items-center space-y-middle">
        {subscriptionIntervalText && (
          <>
            <div className="text-body/caption/default text-support-colors/highlights text-center">
              {t('Subscription:')} {subscriptionIntervalText}
            </div>
            <div className="text-body/caption-2/default text-support-colors/highlights text-center whitespace-pre-line">
              {t(
                'You can manage your subscription on\nthe scheduled transaction details page',
              )}
            </div>
          </>
        )}
        {cmpState === 'queued' && (
          <div className="text-body/caption-2/default text-support-colors/highlights max-w-[211px] whitespace-pre-line text-center">
            {t('Your order is queued.\nThanks for your patience!')}
          </div>
        )}
        {onViewReceiptClick && cmpState !== 'queued' && (
          <BrButton
            cmpType="link"
            size="small"
            onClick={onViewReceiptClick}
            text={t('View receipt')}
          />
        )}
      </div>
    </div>
  );
};

export default BrCompletedOrder;
