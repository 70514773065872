import { useHistory } from 'react-router-dom';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import BrButton from '@components/common/BrButton';
import BrBottomControlsWrapper from '@components/common/BrBottomControlsWrapper';

import useDidMount from '@root/hooks/useDidMount';
import useDidUpdate from '@root/hooks/useDidUpdate';
import useStore from '@root/hooks/useStore';

import PaymentCardStore from '@root/stores/PaymentCardStore';

import { UserAccountStore } from '@root/stores';

import { showBrToast } from '@services/toasts';

import { getImtuRepeatTxnUrl } from '@helpers/urlQuery';

import ImtuTxnDetailsLooperStore from '../ImtuReceipt/ImtuTxnDetailsLooperStore';
import ImtuDetailsSkeleton from './ImtuDetailsSkeleton';
import ImtuTxnDetails from '../Imtu/components/ImtuTxnDetails';

interface Props {
  txnId: string;
}

const ImtuTransactionDetails = (props: Props) => {
  const { txnId } = props;

  const imtuTxnDetailsLooperStore = useLocalStore(() => new ImtuTxnDetailsLooperStore());
  const paymentCardStore = useLocalStore(() => new PaymentCardStore());
  const userAccountStore: UserAccountStore = useStore('UserAccountStore');

  const { t } = useTranslation();

  const history = useHistory();

  const isPositiveStatus = ['success', 'queued'].includes(
    imtuTxnDetailsLooperStore.txn?.status ?? '',
  );

  useDidMount(() => {
    if (txnId) {
      imtuTxnDetailsLooperStore.updateTxnStatusInLoop(txnId);
    }
  });

  useDidUpdate(() => {
    const handleEditableCardRetrieval = async (cardId: string) => {
      try {
        await paymentCardStore.getEditableCardByHandleId(cardId);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('unhandled error', err);
      }
    };
    if (imtuTxnDetailsLooperStore.txn) {
      handleEditableCardRetrieval(imtuTxnDetailsLooperStore.txn.paymentCardId);
    }
  }, [imtuTxnDetailsLooperStore.txn]);

  const handleFinishClick = async () => {
    if (imtuTxnDetailsLooperStore.txn) {
      const url = getImtuRepeatTxnUrl({
        phoneNumber: imtuTxnDetailsLooperStore.txn.recipientPhoneNumber,
        carrierId: imtuTxnDetailsLooperStore.txn.carrier?.code,
        productId: imtuTxnDetailsLooperStore.txn.product?.code,
        language: userAccountStore.currentLanguage,
      });
      history.push(url);
    }
  };

  const handleOnCopyTxnClick = () => {
    navigator?.clipboard?.writeText(txnId).then(() => {
      showBrToast.small(
        `${t('Your link copied to the clipboard\nand is ready to share')} 🎉`,
      );
    });
  };

  return imtuTxnDetailsLooperStore.txn ? (
    <>
      {isPositiveStatus && (
        <>
          {imtuTxnDetailsLooperStore.txn && paymentCardStore.editableCard && (
            <ImtuTxnDetails
              txn={imtuTxnDetailsLooperStore.txn}
              paymentCard={paymentCardStore.editableCard}
              status={imtuTxnDetailsLooperStore.txn?.status}
              onCopyTxnIdClick={handleOnCopyTxnClick}
            />
          )}

          <BrBottomControlsWrapper className="lg:mt-small">
            <BrButton
              text={t('Send again')}
              className="w-full"
              onClick={handleFinishClick}
            />
          </BrBottomControlsWrapper>
        </>
      )}
    </>
  ) : (
    <ImtuDetailsSkeleton />
  );
};

export default observer(ImtuTransactionDetails);
