/* eslint-disable @typescript-eslint/indent */
import { FC, createElement } from 'react';
import { LoaderIcon } from 'react-hot-toast';
import { Img } from 'react-image';

import CountryCode from '@root/interfaces/CountryCode';

import { ReactComponent as UserIcon } from '@src/static/assets/images/icons/legacyIcons/legacy-user-circle.svg';

import { normalizeStringCompound } from '@utils/string';

import BrBadge from '../BrBadge';
import Flag from '../Flag';

import './styles.scss';

const BASIC_CLASS = 'br-contacts';

export interface OnClickProps {
  id: string;
  onClick(id: string): void;
}

export interface BrContactsProps {
  cmpSize?: 'default' | 'large' | 'medium' | 'small';
  cmpType?: 'person' | 'flag' | 'initials' | 'country img';
  countryCode?: CountryCode;
  imageUrl?: string;
  fallbackImageUrl?: string;
  name?: string;
  surname?: string;
  phoneNumber?: string;
  promoText?: string;
  className?: string;
  onClickProps?: OnClickProps;
}

const generateInitials = (name: string, surname: string): string => {
  const initials = name[0] + surname[0];
  return initials;
};

const BrContacts: FC<BrContactsProps> = ({
  cmpSize,
  cmpType,
  countryCode,
  imageUrl,
  fallbackImageUrl,
  promoText,
  name,
  surname,
  phoneNumber,
  className,
  onClickProps,
}) => {
  const wrapperClassNames = normalizeStringCompound([
    BASIC_CLASS,
    `${BASIC_CLASS}-${cmpSize}`,
    `${BASIC_CLASS}-type-${cmpType}`,
    onClickProps ? undefined : 'pointer-none',
    className,
  ]);

  const imageClassNames = normalizeStringCompound([
    'br-image',
    `br-image-${cmpSize}`,
    'relative',
    className,
  ]);

  const shouldRenderFlag = Boolean(
    countryCode && (cmpSize === 'default' || cmpSize === 'large') && cmpType !== 'flag',
  );

  const handleClick = () => {
    onClickProps?.onClick(onClickProps?.id);
  };

  const badgeStyles = normalizeStringCompound([
    'absolute top-[-3px] z-5 -translate-y-1/2',
    cmpSize === 'small' ? '-right-[5px]' : '-right-[2px]',
  ]);

  const circleImageStyles = 'w-full h-full object-cover';

  const contentClassNames = normalizeStringCompound([
    'relative',
    cmpSize === 'default' ? 'space-y-xsmall' : undefined,
    cmpSize === 'large' ? 'space-y-middle' : undefined,
  ]);

  return createElement(onClickProps?.onClick ? 'button' : 'div', {
    className: wrapperClassNames,
    ...(onClickProps?.onClick ? { onClick: handleClick } : undefined),
    children: (
      <div className={contentClassNames}>
        <div className={imageClassNames}>
          {promoText && (
            <BrBadge
              text={promoText}
              classType="warning"
              styleType={cmpSize === 'small' ? 'tail-right' : 'less-contrast-tail-right'}
              className={badgeStyles}
            />
          )}
          {(cmpType === 'country img' || cmpType === 'person') && (
            <div
              className={normalizeStringCompound([
                'h-full w-full overflow-hidden rounded-full',
                cmpSize === 'small'
                  ? '[box-shadow:0_4px_4px_0_rgba(0,0,0,0.25)]'
                  : 'shadow-Shadows/Tooltip',
              ])}
            >
              {imageUrl && (
                <Img
                  src={imageUrl}
                  className={circleImageStyles}
                  alt={name}
                  key={imageUrl}
                  loader={
                    <div className="justify-center flex h-full w-full items-center">
                      <LoaderIcon className="w-1/2 h-1/2 animate-spin" />
                    </div>
                  }
                  unloader={
                    fallbackImageUrl ? (
                      <img
                        className={circleImageStyles}
                        src={fallbackImageUrl}
                        alt={name}
                      />
                    ) : (
                      <div className="w-full h-full">
                        <UserIcon className="w-full h-full" />
                      </div>
                    )
                  }
                />
              )}
              {shouldRenderFlag && (
                <Flag
                  className="w-[24px] h-[24px] absolute bottom-0 right-0 overflow-hidden rounded-full flex-shrink-0 self-center"
                  code={countryCode}
                />
              )}
            </div>
          )}
          {cmpType === 'flag' && (
            <Flag
              className="w-full h-full overflow-hidden rounded-full flex-shrink-0 self-center"
              code={countryCode}
            />
          )}

          {cmpType === 'initials' && name && surname && (
            <div className="br-initials">{generateInitials(name, surname)}</div>
          )}
        </div>

        {name || surname || phoneNumber ? (
          <div className={`br-text-section-${cmpSize}`}>
            {Boolean(name) && <div className={`br-name-${cmpSize}`}>{name}</div>}
            {Boolean(surname) && <div className={`br-surname-${cmpSize}`}>{surname}</div>}
            {Boolean(phoneNumber) && (
              <div className={`br-phone-${cmpSize}`}>{phoneNumber}</div>
            )}
          </div>
        ) : null}
      </div>
    ),
  });
};

BrContacts.defaultProps = {
  cmpSize: 'default',
  cmpType: 'person',
};

export default BrContacts;
