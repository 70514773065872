import Icon from '@components/common/Icon';

import { normalizeStringCompound } from '@utils/string';

import { IconNames } from '@root/interfaces/components/Icon';

import './styles.scss';

export interface ListItemProps {
  className?: string;
  state: 'Success' | 'Failed' | 'Disabled' | 'Queued';
  title: string;
  text?: string;
  additionalText?: string;
}

const ICON_NAME_MAP: Record<ListItemProps['state'], IconNames> = {
  Success: 'checkmark',
  Failed: 'close-outline',
  Disabled: 'radio-button-off-outline',
  Queued: 'time-outline',
};

const BASIC_CLASS = 'br-step-list-item';

const ListItem = (props: ListItemProps) => {
  const { state, title, text, additionalText, className } = props;

  const iconName = ICON_NAME_MAP[state];

  const classNames = normalizeStringCompound([
    BASIC_CLASS,
    `${BASIC_CLASS}-${state}`,
    className,
  ]);

  return (
    <div className={classNames}>
      <div className="relative">
        <Icon width={24} height={24} className="state-icon" name={iconName} />
        {text && <hr className="progress" />}
      </div>
      <div className="space-y-xsmall">
        <div className="title">{title}</div>
        {additionalText && state === 'Failed' && (
          <div className="additional-text">{additionalText}</div>
        )}
        {text && <div className="text">{text}</div>}
      </div>
    </div>
  );
};

export default ListItem;
