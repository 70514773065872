import { useEffect, useRef } from 'react';

const DETAILS_ANIMATION_DURATION = 500;
const OFFER_CARD_PADDING = 16;

const useOfferDetailsInView = (isDetailsOpened?: boolean) => {
  const detailsBtnRef = useRef<HTMLButtonElement>(null);
  const timeoutId = useRef<number>();

  useEffect(() => {
    if (isDetailsOpened) {
      timeoutId.current = window.setTimeout(() => {
        if (detailsBtnRef.current) {
          const { bottom } = detailsBtnRef.current?.getBoundingClientRect();
          const windowHeight = window.innerHeight;
          if (bottom > windowHeight) {
            const margin = bottom - windowHeight + OFFER_CARD_PADDING;
            window.scrollBy({
              top: margin,
              behavior: 'smooth',
            });
          }
        }
      }, DETAILS_ANIMATION_DURATION);
    }
    return () => {
      window.clearTimeout(timeoutId.current);
    };
  }, [isDetailsOpened]);

  return {
    detailsBtnRef,
  };
};

export default useOfferDetailsInView;
