import { useTranslation } from 'react-i18next';

import { CallingApiErrorCode } from '@root/interfaces/contract/ImtuCallingApiError';
import { CALLING_ERRORS_MSG_MAP } from '@root/constants/errorMessages';

interface Props {
  errorCode?: CallingApiErrorCode;
}

const errorCodeToTextMap: Record<CallingApiErrorCode | string, string> = {
  failed: 'Transaction declined', // t('Transaction declined'),
  insufficient_funds: 'Insufficient funds', // t('Insufficient funds'),
  failed_no_credit: 'Insufficient funds', // t('Insufficient funds'),
  failed_card_expired: 'Card expired', // t('Card expired')
  failed_card_restricted: 'Card restricted', // t('Card restricted')
  failed_card_declined: 'Card declined', // t('Card declined')
  failed_invalid_card_info: 'Card declined', // t('Invalid card information')
  failed_invalid_card_number: 'Invalid Card Number', // t('Invalid Card Number')
  failed_no_card: 'Card missing', // t('Card missing'),
};

const CHANGE_CARD_ERROR_CODES: CallingApiErrorCode[] = [
  'failed',
  '3ds_failed',
  'insufficient_funds',
  'failed_card_expired',
  'failed_card_restricted',
  'failed_card_declined',
  'failed_card_expired',
  'failed_card_expired',
  'failed_invalid_card_info',
  'failed_invalid_card_number',
  'failed_no_card',
  'failed_lost_or_stolen_cc',
  'failed_no_credit',
  'failed_try_again',
  'insufficient_funds',
  'unknown',
];

const useChangeCardErrorHandler = (props: Props) => {
  const { errorCode } = props;
  const { t } = useTranslation();

  const isErrorReasonRelatedToCard =
    errorCode && CHANGE_CARD_ERROR_CODES.includes(errorCode);

  if (!errorCode || !isErrorReasonRelatedToCard) {
    return null;
  }

  const errorTitle = errorCodeToTextMap[errorCode] || errorCodeToTextMap.failed;

  return {
    isErrorReasonRelatedToCard,
    errorTitle,
    errorDescription: t(CALLING_ERRORS_MSG_MAP[errorCode]),
  };
};

export default useChangeCardErrorHandler;
