import { normalizeStringCompound } from '@utils/string';

import ListItem, { ListItemProps as Step } from './components/ListItem';

interface Props {
  config: Array<Step>;
  className?: string;
}

const BrDetails = (props: Props) => {
  const { config, className } = props;

  return (
    <div
      className={normalizeStringCompound([
        className,
        'grid space-y-middle grid-flow-row',
      ])}
    >
      {config.map((params, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <ListItem key={idx} {...params} />
      ))}
    </div>
  );
};

export default BrDetails;
