import { useParams } from 'react-router-dom';

import ImtuTransactionDetails from '@components/modules/ImtuTransactionDetails';
import BrTemplateWithSidebar from '@components/templates/BrTepmplateWithSidebar';

interface UrlParams {
  id: string;
}

const ImtuTxnDetailsPage = () => {
  const { id: txnId } = useParams<UrlParams>();

  return (
    <BrTemplateWithSidebar>
      <ImtuTransactionDetails txnId={txnId} />
    </BrTemplateWithSidebar>
  );
};

export default ImtuTxnDetailsPage;
