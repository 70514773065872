import { normalizeStringCompound } from '@utils/string';

import ListItem, { ListItemProps as Step } from './components/ListItem';

interface Props {
  config: Array<Step>;
  className?: string;
}

const BrStepper = (props: Props) => {
  const { config, className } = props;

  return (
    <div className={normalizeStringCompound([className, 'grid gap-large grid-flow-row'])}>
      {config.map((step, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <ListItem key={idx} {...step} />
      ))}
    </div>
  );
};

export default BrStepper;
