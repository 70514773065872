import React from 'react';
import { observer } from 'mobx-react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { ImtuStatus } from '@root/interfaces/MobileTopUp';
import CountryCode from '@root/interfaces/CountryCode';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';
import ImtuProductClass from '@root/models/ImtuProductClass';
import PaymentCardClass from '@root/models/PaymentCardClass';
import ImtuPromoOfferDetailsClass from '@root/models/ImtuPromoOfferDetailsClass';
import BrCard from '@components/common/BrCard';
import BrReceipt from '@components/common/BrReceiptComp';
import BrOrderStatus from '@components/common/BrOrderStatus';

import { formatPhone } from '@helpers/phone';

import { showBrToast } from '@services/toasts';

import ReceiptHeader from './components/ReceiptHeader';

import getImtuReceiptCompConfig from './getImtuReceiptCompConfig';

import './styles.scss';

interface Props {
  txnId: string;
  submitDate: Date;
  txnStatus: ImtuStatus;
  countryCode: CountryCode;
  recipientPhoneNumber: string;
  carrier?: ImtuCarrierClass;
  product?: ImtuProductClass;
  paymentInfo?: PaymentCardClass | null;
  promoInfo?: ImtuPromoOfferDetailsClass;
  txnErrorCode?: string;
}

const Receipt: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    txnId,
    submitDate,
    txnStatus,
    carrier,
    product,
    paymentInfo,
    countryCode,
    recipientPhoneNumber,
    promoInfo,
  } = props;

  const { t } = useTranslation();

  const isPositiveStatus = ['success', 'queued'].includes(txnStatus);

  const handleOnCopyBtnClick = () => {
    navigator?.clipboard?.writeText(txnId).then(() => {
      showBrToast.small(
        `${t('Your link copied to the clipboard\nand is ready to share')} 🎉`,
      );
    });
  };

  return (
    <div className="mb-xxxlarge">
      <div className="flex flex-col items-center w-full space-y-middle">
        <BrOrderStatus
          status="Receipt"
          title={t('Thank you')}
          subTitle={t('Your receipt')}
        />

        {isPositiveStatus && product && paymentInfo && (
          <>
            <BrCard variant="without-paddings" className="w-full">
              <BrReceipt
                header={
                  carrier && (
                    <ReceiptHeader
                      countryCode={countryCode}
                      phone={formatPhone(recipientPhoneNumber, countryCode)}
                      subType={product?.subType}
                      carrierCode={carrier?.code}
                      priceText={
                        product.subType === 'Mobile Bundle'
                          ? product.name
                          : product.recipientAmountText
                      }
                    />
                  )
                }
                config={getImtuReceiptCompConfig({
                  confirmationNumber: txnId,
                  onCopyBtnClick: handleOnCopyBtnClick,
                  description: product.description,
                  maskedCardNumber: paymentInfo.maskedNumber,
                  formatedDateText: format(submitDate, 'MMMM dd, yyyy'),
                  subTotal: product.senderBaseAmountText,
                  serviceFee: product.feeText,
                  discount: promoInfo?.discountText ?? undefined,
                  totalAmount: product.senderTotalAmountText,
                })}
              />
            </BrCard>
          </>
        )}
      </div>
    </div>
  );
};

export default observer(Receipt);
