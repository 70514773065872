/* eslint-disable @typescript-eslint/indent */
import { ListItemProps } from '@components/common/BrDetails/components/ListItem';
import { BrReceiptCompConfig } from '@components/common/BrReceiptComp';

interface Params {
  confirmationNumber: string;
  description?: string;
  maskedCardNumber: string;
  formatedDateText: string;
  subTotal: string;
  serviceFee: string;
  discount?: string;
  totalAmount: string;
  onCopyBtnClick?(): void;
}

const getImtuReceiptCompConfig = (params: Params): BrReceiptCompConfig => {
  const {
    confirmationNumber,
    description,
    maskedCardNumber,
    formatedDateText,
    subTotal,
    serviceFee,
    discount,
    totalAmount,
    onCopyBtnClick,
  } = params;

  return [
    {
      data: [
        {
          type: 'bold',
          label: 'Confirmation number', // t('Confirmation number')
          text: confirmationNumber,
          iconName: 'copy-mix',
          shouldShowButton: true,
          onActionBtnClick: onCopyBtnClick,
        },
        description?.length
          ? {
              type: 'bold',
              label: 'Product details', // t('Product details')
              text: description,
            }
          : undefined,
        {
          type: 'bold',
          label: 'Payment details', // t('Payment details')
          text: maskedCardNumber,
        },
        {
          type: 'bold',
          label: 'Other details', // t('Other details')
          text: formatedDateText,
        },
      ].filter(Boolean) as ListItemProps[],
    },
    {
      data: [
        {
          type: 'bold',
          text: 'Order summary', // t('Order summary'),
        },
        {
          type: 'small',
          label: 'Subtotal', // t('Subtotal')
          text: subTotal,
        },
        {
          type: 'small',
          label: 'Service fee', // t('Service fee')
          text: serviceFee,
        },
        discount?.length
          ? {
              type: 'small',
              label: 'Discount', // t('Discount')
              text: discount,
            }
          : undefined,
        {
          type: 'large',
          label: 'Your total', // t('Your total')
          text: totalAmount,
        },
      ].filter(Boolean) as ListItemProps[],
    },
  ];
};

export default getImtuReceiptCompConfig;
