import React, {
  ComponentProps,
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import BrCardFunctionality from '@components/common/BrCardFunctionality';

import { normalizeStringCompound } from '@utils/string';

import BrBadge from '../BrBadge';
import BrButton, { BrButtonProps } from '../BrButton';
import BrCard, { BrCardProps } from '../BrCard';
import BrAlert from '../BrAlert';
import BrRecipientNumberForm from './BrRecipientNumberForm';
import BrScrollShadowWrapper from '../BrScrollShadowWrapper';
import BrExpandableContainer from '../BrAnimateHeightContainer';

import useOfferDetailsInView from './useOfferDetailsInView';

type BundledProduct = {
  title: string;
  descriptionShort?: string;
  descriptionLong: string;
};

type ActionType = 'manage' | 'purchase';
const ACTION_TYPE_BUTTON_STYLE: Record<ActionType, BrButtonProps['cmpType']> = {
  manage: 'light',
  purchase: 'solid',
};

interface Props {
  id: string;
  className?: string;
  expandableContainerClassNames?: string;
  actionText?: string;
  actionType?: ActionType;
  planTypeText: string;
  promoBadgeText?: string;
  title: string;
  descriptionShort?: string;
  discountText?: string;
  functionalAddon?: ReactNode;
  isFeatured?: boolean;
  featuredText?: string;
  isNonPurchasable?: boolean;
  isCombo?: boolean;
  isWithFixedPromo?: boolean;
  isDetailsOpen?: boolean;
  isFullWidthBtn?: boolean;
  hasScrollableDetailsContainer?: boolean;
  bundledProduct?: BundledProduct;
  expirationContent?: React.ReactNode;
  unavailableContent?: string;
  recipientFormCfg?: ComponentProps<typeof BrRecipientNumberForm>;
  detailsAnimationDuration?: number;
  onActionBtnClick?(id: string): void;
  onDetailsOpen?(id: string): void;
  onEditBtnClick?(): void;
}

const BrOfferCard: FC<PropsWithChildren<Props>> = (props) => {
  const {
    id,
    className,
    expandableContainerClassNames,
    featuredText,
    actionText,
    actionType,
    planTypeText,
    promoBadgeText,
    children,
    title,
    descriptionShort,
    discountText,
    bundledProduct,
    functionalAddon,
    isNonPurchasable,
    isCombo,
    isFeatured,
    isWithFixedPromo,
    isDetailsOpen,
    isFullWidthBtn,
    hasScrollableDetailsContainer,
    expirationContent,
    unavailableContent,
    recipientFormCfg,
    onActionBtnClick,
    onEditBtnClick,
    onDetailsOpen,
  } = props;

  const [isDetailsOpened, setIsDetailsOpened] = useState(isDetailsOpen);

  const { t } = useTranslation();

  useEffect(() => {
    setIsDetailsOpened(isDetailsOpen);
  }, [isDetailsOpen]);

  const handleOnDetailsClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDetailsOpened((isOpen) => !isOpen);
  };

  const handleOnActionButtonClick = () => {
    onActionBtnClick?.(id);
  };

  const handleOnDetailsDescriptionClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    const selection = window.getSelection();
    if (selection?.isCollapsed) {
      setIsDetailsOpened((s) => !s);
    }
  };

  const handleOnDetailsDescriptionSelect = (
    e: React.SyntheticEvent<HTMLButtonElement, Event>,
  ) => {
    e.stopPropagation();
  };

  const handleOnPromoBadgeClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDetailsOpened((s) => !s);
  };

  useEffect(() => {
    if (isDetailsOpened) {
      onDetailsOpen?.(id);
    }
  }, [isDetailsOpened]);

  const { detailsBtnRef } = useOfferDetailsInView(isDetailsOpened);

  const brCardProps: BrCardProps =
    isFeatured && featuredText
      ? { variant: 'featured', featuredText }
      : { variant: isWithFixedPromo ? 'with-promo-sheen' : undefined };

  return (
    <BrCard
      {...brCardProps}
      onClick={onActionBtnClick ? handleOnActionButtonClick : undefined}
      className={className}
    >
      <div className="space-y-middle">
        <div className="flex justify-between items-center">
          <div className="flex space-x-small">
            <BrBadge classType="neutral" styleType="default" text={planTypeText} />
            {promoBadgeText && (
              <button onClick={handleOnPromoBadgeClick}>
                <BrBadge
                  iconLeft="egift-solid"
                  text={promoBadgeText}
                  classType="warning"
                  className="h-full"
                />
              </button>
            )}
            {isCombo && (
              <BrBadge
                iconLeft="sparkles"
                styleType="less-contrast"
                classType="attention"
                text={t('Combo plan')}
              />
            )}
          </div>
          {onEditBtnClick && (
            <BrButton
              cmpType="link"
              text={t('Edit')}
              className="!py-0 !pr-0 !leading-0"
              onClick={onEditBtnClick}
            />
          )}
        </div>
        {Boolean(discountText) && (
          <div className="line-through text-body/footnote/default text-support-colors/secondary-text text-right">
            {discountText}
          </div>
        )}
        <div
          className={normalizeStringCompound([
            isFullWidthBtn ? 'flex-col space-y-middle' : 'space-x-middle items-center',
            'flex justify-between ',
          ])}
        >
          <div className="text-body/primary/demibold flex-1 flex text-left tap-highlight-transparent">
            {title}
          </div>
          {onActionBtnClick && (
            <BrButton
              size="small"
              onClick={handleOnActionButtonClick}
              text={actionText}
              className={normalizeStringCompound([
                isFullWidthBtn ? 'w-full' : 'min-w-[104px]',
              ])}
              cmpType={
                actionType && ACTION_TYPE_BUTTON_STYLE[actionType]
                  ? ACTION_TYPE_BUTTON_STYLE[actionType]
                  : 'solid'
              }
              disabled={isNonPurchasable}
            />
          )}
        </div>
        {/* Details section */}
        {children && (
          <>
            <div className="border-t border-solid border-t-support-colors/lines" />
            <div className="flex flex-row items-center justify-between">
              <div className="text-support-colors/highlights text-body/footnote/default">
                {descriptionShort}
              </div>
              <div>
                <BrButton
                  cmpType="text"
                  size="xSmall"
                  text={t('Details')}
                  iconName={isDetailsOpened ? 'up_-_top' : 'bottom_-_down'}
                  hasIconRight
                  onClick={handleOnDetailsClick}
                />
              </div>
            </div>
            <div
              className={normalizeStringCompound([
                '!m-0 flex flex-col relative overflow-hidden',
                expandableContainerClassNames,
              ])}
            >
              {hasScrollableDetailsContainer ? (
                isDetailsOpened && (
                  <BrScrollShadowWrapper className="-mr-xlarge -ml-xlarge pl-xlarge pr-xlarge [&_.simplebar-track]:!right-0 [&_.simplebar-track]:!z-100">
                    <button
                      className="text-left select-text w-full"
                      onClick={handleOnDetailsDescriptionClick}
                      onSelect={handleOnDetailsDescriptionSelect}
                    >
                      <div className="mt-middle">{children}</div>
                    </button>
                  </BrScrollShadowWrapper>
                )
              ) : (
                <BrExpandableContainer
                  isExpanded={isDetailsOpened}
                  expandableContainerClassNames={expandableContainerClassNames}
                >
                  <button
                    ref={detailsBtnRef}
                    className="text-left select-text w-full"
                    onClick={handleOnDetailsDescriptionClick}
                    onSelect={handleOnDetailsDescriptionSelect}
                  >
                    <div className="mt-middle">{children}</div>
                  </button>
                </BrExpandableContainer>
              )}
            </div>
          </>
        )}
        {/* Combo plan bundled Imtu product */}
        {bundledProduct && (
          <div className="mt-middle">
            <BrCardFunctionality
              iconName="sparkles"
              title={bundledProduct.title}
              text={
                isDetailsOpened
                  ? `${bundledProduct.descriptionShort}\n${bundledProduct.descriptionLong}`
                  : bundledProduct.descriptionShort
              }
            >
              {recipientFormCfg ? (
                <BrRecipientNumberForm
                  inputLabel={t('Phone number')}
                  onPhoneChange={recipientFormCfg.onPhoneChange}
                  externalValidationFn={recipientFormCfg.externalValidationFn}
                  onValidation={recipientFormCfg.onValidation}
                />
              ) : null}
            </BrCardFunctionality>
          </div>
        )}
        {/* Favorite number form etc. */}
        {functionalAddon}
        {expirationContent && (
          <BrAlert classType="warning-variant" className="w-full !whitespace-normal">
            {expirationContent}
          </BrAlert>
        )}
        {unavailableContent && (
          <BrAlert classType="neutral" className="w-full" text={unavailableContent} />
        )}
      </div>
    </BrCard>
  );
};

export default BrOfferCard;
