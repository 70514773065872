import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import BrButton from '../BrButton';

import BrExpandableContainer from '../BrAnimateHeightContainer';
import BrAlert, { BrAlertProps } from '../BrAlert';

import useOfferDetailsInView from '../BrOfferCard/useOfferDetailsInView';

type BundledProduct = {
  title: string;
  descriptionShort?: string;
  descriptionLong: string;
};

type ActionType = 'manage' | 'purchase';

export interface BrCardContentProps {
  id: string;
  actionText?: string;
  actionType?: ActionType;
  planTypeText: string;
  promoBadgeText?: string;
  title: string;
  descriptionShort?: string;
  functionalAddon?: ReactNode;
  isNonPurchasable?: boolean;
  isCombo?: boolean;
  isDetailsOpen?: boolean;
  bundledProduct?: BundledProduct;
  expirationContent?: React.ReactNode;
  unavailableContent?: string;
  onActionBtnClick?(id: string): void;
  onDetailsBtnClick?(): void;
  onDetailsContentClick?(): void;
  onEditBtnClick?(): void;
  brAlertCfg?: BrAlertProps;
  brAlertContent?: string;
  details: ReactNode;
}

const BrCardContent: FC<PropsWithChildren<BrCardContentProps>> = (props) => {
  const {
    id,
    actionText,
    title,
    descriptionShort,
    isNonPurchasable,
    isDetailsOpen,
    onActionBtnClick,
    details,
    brAlertCfg,
    brAlertContent,
    onDetailsBtnClick,
    onDetailsContentClick,
  } = props;

  const { t } = useTranslation();

  const handleOnDetailsBtnClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDetailsBtnClick?.();
  };

  const handleOnActionButtonClick = () => {
    onActionBtnClick?.(id);
  };

  const handleOnContentSelect = (e: React.SyntheticEvent<HTMLButtonElement, Event>) => {
    e.stopPropagation();
  };

  const handleOnContentClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    const selection = window.getSelection();
    if (selection?.isCollapsed) {
      onDetailsContentClick?.();
    }
  };

  const { detailsBtnRef } = useOfferDetailsInView(isDetailsOpen);

  return (
    <div className="br-card--content space-y-small">
      <div className="flex flex-col md:flex-row items-stretch md:items-baseline md:justify-between  space-y-small md:space-x-small md:space-y-0">
        <div className="text-body/primary/demibold text-on-colors/on-secondary flex-1 flex text-left tap-highlight-transparent">
          {title}
        </div>
        {onActionBtnClick && (
          <BrButton
            size="small"
            onClick={handleOnActionButtonClick}
            text={actionText}
            className="min-w-[180px]"
            cmpType="white"
            hasIconRight
            iconName="arrow-forward"
            disabled={isNonPurchasable}
          />
        )}
      </div>
      {/* Details section */}
      {details && (
        <>
          <div className="flex flex-row items-center justify-between align-baseline">
            <div className="text-color/chat-bubbles text-body/footnote/default">
              {descriptionShort}
            </div>
            <div>
              <BrButton
                cmpType="solid"
                size="xSmall"
                text={t('Details')}
                iconName={isDetailsOpen ? 'up_-_top' : 'bottom_-_down'}
                hasIconRight
                onClick={handleOnDetailsBtnClick}
              />
            </div>
          </div>
          <div className="!m-0">
            <BrExpandableContainer isExpanded={isDetailsOpen}>
              <button
                ref={detailsBtnRef}
                className="text-left select-text w-full"
                onSelect={handleOnContentSelect}
                onClick={handleOnContentClick}
              >
                <div className="mt-small space-y-small">
                  {brAlertContent && <BrAlert {...brAlertCfg}>{brAlertContent}</BrAlert>}
                  <div className="text-on-colors/on-secondary text-body/callout/default whitespace-break-spaces">
                    <div>{details}</div>
                  </div>
                </div>
              </button>
            </BrExpandableContainer>
          </div>
        </>
      )}
    </div>
  );
};

export default BrCardContent;
