import { v4 as uuid } from 'uuid';

import BrDetail, { ListItemProps } from '../BrDetails/components/ListItem';
import { CarrierInfo } from '../BrListItem';
import Circle from '../BrReceipt/components/Circle';
import Divider from '../BrReceipt/components/Divider';

export type BrReceiptCompConfig = Array<{ data: ListItemProps[]; sectionTitle?: string }>;

interface Props {
  config: BrReceiptCompConfig;
  header: React.ReactNode;
}

const BrReceipt = (props: Props) => {
  const { config, header } = props;

  return (
    <div className="py-default">
      <div className="min-h-[48px] relative px-xlarge">
        <Circle className="absolute left-0 -translate-x-1/2" />
        <Circle className="absolute right-0 translate-x-1/2" />
        {header}
      </div>
      <Divider className="my-xlarge" />
      {config.map((sectionDetails, idx, arr) => (
        <>
          {sectionDetails.sectionTitle && (
            <>
              <CarrierInfo
                name={sectionDetails.sectionTitle}
                size="xSmall"
                className="px-default flex justify-center"
                classNameText="text-center"
              />
              <Divider className="m-default" />
            </>
          )}
          <div key={uuid()} className="px-default space-y-small">
            {sectionDetails.data.map((detail) => (
              <BrDetail key={uuid()} {...detail} />
            ))}
          </div>
          {arr.length - 1 > idx && <Divider className="m-default" key={uuid()} />}
        </>
      ))}
    </div>
  );
};

export default BrReceipt;
