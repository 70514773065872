import React from 'react';
import { useTranslation } from 'react-i18next';

import CountryCode from '@root/interfaces/CountryCode';

import { RecipientInfo } from '@components/common/BrListItem';
import Icon from '@components/common/Icon';
import BrButton from '@components/common/BrButton';

interface Props {
  phoneNumber: string;
  countryCode: CountryCode;
  statusText: string;
  errorText: string;
  errorDescription: string;
  onSeeDetailsClick?(): void;
}

const BrFailedOrder: React.FC<Props> = (props) => {
  const {
    phoneNumber,
    countryCode,
    statusText,
    errorText,
    errorDescription,
    onSeeDetailsClick,
  } = props;
  const { t } = useTranslation();
  return (
    <div className="flex flex-col space-y-xlarge">
      <RecipientInfo
        badgeText={t('Recipient')}
        name={phoneNumber} // Design hacks, they use phone number for name property
        countryCode={countryCode}
      />
      <div className="flex flex-row space-x-small">
        <div>
          <Icon
            name="close-outline"
            className="text-color/error"
            width={24}
            height={24}
          />
        </div>
        <div className="flex flex-col space-y-xsmall">
          <div className="text-headers/header-4 text-on-colors/on-primary">
            {statusText}
          </div>
          <div className="flex flex-col space-y-xsmall">
            <div className="text-body/primary/default text-tap-colors/tap-error">
              {errorText}
            </div>
            <div className="text-body/caption/default text-support-colors/highlights">
              {errorDescription}
            </div>
          </div>
        </div>
      </div>
      <BrButton
        cmpType="link"
        size="small"
        hasIconLeft
        iconName="reader-outline"
        text={t('See details')}
        onClick={onSeeDetailsClick}
      />
    </div>
  );
};

export default BrFailedOrder;
