import BrSkeleton from '@components/common/BrSkeleton';

const ImtuDetailsSkeleton = () => {
  return (
    <div className="flex flex-col w-full items-center space-y-default">
      <BrSkeleton className="w-full h-[145px] rounded-default" />
      <BrSkeleton className="w-full h-[80px] rounded-default" />
      <BrSkeleton className="w-full h-[140px] rounded-default" />
      <BrSkeleton className="w-full h-[110px] rounded-default" />
      <BrSkeleton className="w-full h-[55px] rounded-default" />
    </div>
  );
};

export default ImtuDetailsSkeleton;
