import { normalizeStringCompound } from '@utils/string';

import BrButton from '@components/common/BrButton';
import { IconNames } from '@root/interfaces/components/Icon';
import Icon from '@components/common/Icon';

import './styles.scss';

export interface ListItemProps {
  className?: string;
  /* eslint-disable @typescript-eslint/indent */
  type:
    | 'default'
    | 'default-icon-with-bg'
    | 'bold'
    | 'bold-icon-with-bg'
    | 'small'
    | 'large';
  /* eslint-disable @typescript-eslint/indent */
  label?: string;
  text: string;
  additionalText?: string;
  iconName?: IconNames;
  onActionBtnClick?: () => void;
  shouldShowButton?: boolean;
}

const BASIC_CLASS = 'br-details-list-item';

const ListItem = (props: ListItemProps) => {
  const {
    label,
    type,
    text,
    additionalText,
    className,
    iconName,
    onActionBtnClick,
    shouldShowButton,
  } = props;

  const classNames = normalizeStringCompound([
    BASIC_CLASS,
    `${BASIC_CLASS}-${type}`,
    className,
  ]);

  const btnCompType =
    type === 'bold-icon-with-bg' || type === 'default-icon-with-bg' ? 'text' : 'gray';

  return (
    <div className={classNames}>
      {type === 'large' && (
        <div className="space-x-xsmall flex justify-between">
          {label && <div className="label">{label}</div>}
          <div className="text">{text}</div>
        </div>
      )}
      {type === 'small' && (
        <div className="space-y-xsmall">
          {additionalText && (
            <div className="space-x-xsmall flex items-center text-support-colors/highlights text-body/caption-2/default">
              {iconName && (
                <Icon className="btn-icon" name={iconName} width={12} height={12} />
              )}
              <span>{additionalText}</span>
            </div>
          )}

          <div className="space-x-xsmall flex flex-row justify-between">
            {label && <div className="label">{label}</div>}
            <div className="text">{text}</div>
          </div>
        </div>
      )}
      {type !== 'large' && type !== 'small' && (
        <div className="space-x-xsmall flex flex-row justify-between">
          <div className="space-y-small">
            {label && <div className="label">{label}</div>}
            <div className="text">{text}</div>
          </div>
          {shouldShowButton && (
            <div>
              <BrButton
                iconName={iconName}
                cmpType={btnCompType}
                cmpStyle="default"
                hasIconRight
                onClick={onActionBtnClick}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ListItem;
