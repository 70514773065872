import { useHistory, useLocation } from 'react-router-dom';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import BrCompletedOrder from '@components/common/BrCompletedOrder';
import BrCard from '@components/common/BrCard';
import BrButton from '@components/common/BrButton';
import BrBottomControlsWrapper from '@components/common/BrBottomControlsWrapper';
import BrFailedOrder from '@components/common/BrFailedOrder';
import useChangeCardErrorHandler from '@components/modules/ImtuCompletedOrder/useChangeCardErrorHandler';

import useDidMount from '@root/hooks/useDidMount';

import ROUTE_PATHS from '@root/routes/paths';

import { getImtuChangeCardUrl, getImtuReceiptUrl } from '@helpers/urlQuery';

import ImtuTxnDetailsLooperStore from '../ImtuReceipt/ImtuTxnDetailsLooperStore';
import CompletedOrderSkeleton from '../ImtuReceipt/components/ReceiptSkeleton';
import Status from '../ImtuReceipt/components/Receipt/components/Status';

interface Props {
  txnId: string;
}

export type ImtuCompletedOrderLocationState =
  | { data: { denomText: string; carrierPromo?: string } }
  | undefined;

const ImtuCompletedOrder = (props: Props) => {
  const { txnId } = props;

  const imtuTxnDetailsLooperStore = useLocalStore(() => new ImtuTxnDetailsLooperStore());

  const { t } = useTranslation();

  const history = useHistory();

  const location = useLocation<ImtuCompletedOrderLocationState>();

  useDidMount(() => {
    if (txnId) {
      imtuTxnDetailsLooperStore.updateTxnStatusInLoop(txnId);
    }
  });

  const isPositiveStatus = ['success', 'queued'].includes(
    imtuTxnDetailsLooperStore.txn?.status ?? '',
  );

  const isQueued = imtuTxnDetailsLooperStore.txn?.status === 'queued';

  const orderDescription =
    imtuTxnDetailsLooperStore.txn?.product?.subType === 'Mobile Top Up'
      ? imtuTxnDetailsLooperStore.txn.product.recipientAmountText
      : imtuTxnDetailsLooperStore.txn?.product?.name;

  const handleOnViewReceiptClick = () => {
    history.push(getImtuReceiptUrl(txnId));
  };

  const handleChangeCardClick = () => {
    const url = getImtuChangeCardUrl({
      phoneNumber: imtuTxnDetailsLooperStore.txn?.recipientPhoneNumber,
      carrierId: imtuTxnDetailsLooperStore.txn?.carrier?.code,
      productId: imtuTxnDetailsLooperStore.txn?.product?.code,
    });
    history.push(url);
  };

  const handleFinishClick = () => {
    history.push(ROUTE_PATHS.ACCOUNT);
  };

  const handleSendAnotherTopupBtnClick = () => {
    history.push(ROUTE_PATHS.IMTU);
  };

  const handleSeeDetailsClick = () => {
    // TODO: uncomment when txn details page will support failed transactions
    // history.push(`${PATHS.IMTU_TXN_DETAILS}/${imtuTxnDetailsLooperStore.txn?.id}`);
  };

  const changeCardErrorInfo = useChangeCardErrorHandler({
    errorCode: imtuTxnDetailsLooperStore.txn?.errorCode,
  });

  return imtuTxnDetailsLooperStore.txn ? (
    <>
      <Status
        txnStatus={imtuTxnDetailsLooperStore.txn?.status}
        txnErrorCode={imtuTxnDetailsLooperStore.txn?.errorCode}
      />
      {isPositiveStatus && (
        <>
          <BrCard className="mb-xxxlarge mt-default">
            <BrCompletedOrder
              cmpVersion="with-photo"
              cmpState={isQueued ? 'queued' : 'default'}
              phone={imtuTxnDetailsLooperStore.txn.recipientPhoneNumber}
              countryCode={imtuTxnDetailsLooperStore.txn.recipientCountryCode}
              carrierCode={imtuTxnDetailsLooperStore.txn.carrier?.code}
              orderDescription={
                (location?.state?.data?.denomText || orderDescription) ?? ''
              }
              onViewReceiptClick={
                imtuTxnDetailsLooperStore.txn?.status === 'success'
                  ? handleOnViewReceiptClick
                  : undefined
              }
              carrierPromoText={
                location?.state?.data?.carrierPromo ||
                imtuTxnDetailsLooperStore.txn.product?.carrierPromo?.title
              }
            />
          </BrCard>
          <BrButton
            cmpType="text"
            hasIconLeft
            iconName="top-up-outline"
            className="mt-xlarge w-full"
            text={t('Send another top up')}
            onClick={handleSendAnotherTopupBtnClick}
          />
          <BrBottomControlsWrapper className="lg:mt-small">
            <BrButton
              text={t("I'm done")}
              className="w-full"
              onClick={handleFinishClick}
            />
          </BrBottomControlsWrapper>
        </>
      )}
      {changeCardErrorInfo && (
        <div className="flex flex-col space-y-xlarge">
          <BrCard className="mt-default">
            <BrFailedOrder
              phoneNumber={imtuTxnDetailsLooperStore.txn.recipientPhoneNumber}
              countryCode={imtuTxnDetailsLooperStore.txn.recipientCountryCode}
              statusText={t('Payment')} // Supposed to be always payment, until we get real status
              errorText={changeCardErrorInfo.errorTitle}
              errorDescription={changeCardErrorInfo.errorDescription}
              onSeeDetailsClick={handleSeeDetailsClick}
            />
          </BrCard>
          <BrBottomControlsWrapper className="lg:mt-small">
            <BrButton
              text={changeCardErrorInfo ? t('Change card & submit order') : t("I'm done")}
              className="w-full"
              onClick={changeCardErrorInfo ? handleChangeCardClick : handleFinishClick}
            />
          </BrBottomControlsWrapper>
        </div>
      )}
    </>
  ) : (
    <CompletedOrderSkeleton />
  );
};

export default observer(ImtuCompletedOrder);
