import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StepModule } from '@root/interfaces/StepModules';
import CountryCode from '@root/interfaces/CountryCode';
import { CountryAliases } from '@root/interfaces/appConfig';

import BrBottomControlsWrapper from '@components/common/BrBottomControlsWrapper';
import BrButton from '@components/common/BrButton';
import BrCard from '@components/common/BrCard';
import BrTooltipWrapper from '@components/common/BrTooltip/BrTooltipWrapper';
import { getNormalizedCountryDialCode } from '@components/common/PhoneInput/helpers';

import { getCountryNameByCode } from '@services/countryList';

import { getPhoneDialCodeByCountryCode } from '@helpers/phone';

import { getSortByKeyFn } from '@utils/array';

import BrComboSelect, { ComboSelectRef } from '@components/common/BrComboSelect';

import { ValidationError } from './components/PhoneForm';
import Title from '../../components/Title';

interface Props extends StepModule {
  phone?: string;
  countryCode: CountryCode;
  countries: CountryCode[];
  countryAliases?: CountryAliases[];
  onFinish(args: { recipientPhoneNumber: string }): void;
  onCountryChange?(code: CountryCode): void;
}

/**
 * Well this component doesn't use any store or complex logic like containers do
 * but to keep things consistent let's assume this is a container.
 * The other solution is to put it to Imtu components.
 * @param props
 * @constructor
 */
const Phone: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    phone,
    countryCode,
    dataTestPrefix,
    onFinish,
    countries,
    onCountryChange,
    countryAliases,
  } = props;

  const { t } = useTranslation();

  const comboSelectRef = useRef<ComboSelectRef>(null);

  const [validationResult, setValidationResult] = useState<{
    isValid: boolean;
    errorReason?: ValidationError;
  }>({ isValid: false, errorReason: 'common' });
  const [isCountryInputFocused, setIsCountryInputFocused] = useState(false);

  const selectData = [
    {
      id: '1',
      label: t('All'),
      options: countries
        .map((code) => {
          return {
            value: code,
            text: `${t(getCountryNameByCode(code))}` ?? '',
            addonTextLeft: `+${getPhoneDialCodeByCountryCode(code)}`,
          };
        })
        .sort(getSortByKeyFn('text')),
    },
  ];

  const countryName = t(getCountryNameByCode(countryCode));
  const phoneCode = t(getNormalizedCountryDialCode(countryCode));

  const continueBtnTooltipText = () => {
    switch (true) {
      case validationResult.errorReason === 'common':
      case validationResult.errorReason === 'empty':
        return t('Please provide a phone number');
      case validationResult.errorReason === 'invalidPhone':
      case validationResult.errorReason === 'invalidCountry':
        return t('Please provide a valid phone number');
      case validationResult.errorReason === 'invalidDialCode':
        return t(
          'Please enter a valid phone code. For {{countryName}}, use {{phoneCode}}.',
          {
            countryName,
            phoneCode,
          },
        );
      default:
        return undefined;
    }
  };

  const handleOnValidation = useCallback(
    (res: { isValid: boolean; errorReason?: ValidationError }) => {
      setValidationResult({ isValid: res.isValid, errorReason: res.errorReason });
    },
    [],
  );

  const handleOnCountryChange = (code: CountryCode) => {
    onCountryChange?.(code);
    setValidationResult({ isValid: false });
  };

  const handleFinish = (phoneNumber: string) => {
    if (phoneNumber) {
      onFinish({ recipientPhoneNumber: phoneNumber });
    }
  };

  const handleRemoteSubmit = () => {
    comboSelectRef.current?.submit();
  };

  const handleOnCountryInputFocus = () => {
    setIsCountryInputFocused(true);
  };

  const handleOnCountryInputBlur = () => {
    setIsCountryInputFocused(false);
  };

  return (
    <BrCard className="!overflow-visible space-y-default">
      <Title
        text={
          isCountryInputFocused
            ? t("Select recipient's country")
            : t("Add recipient's phone number")
        }
      />
      <BrComboSelect
        countryCode={countryCode}
        data={selectData}
        onSubmit={handleFinish}
        onCountryChange={handleOnCountryChange}
        onPhoneValidation={handleOnValidation}
        phone={phone}
        countryAliases={countryAliases}
        ref={comboSelectRef}
        onCountryInputFocus={handleOnCountryInputFocus}
        onCountryInputBlur={handleOnCountryInputBlur}
      />
      <BrBottomControlsWrapper className="bg-color/primary">
        <BrTooltipWrapper
          cfg={{
            place: 'bottom',
            content: continueBtnTooltipText(),
          }}
        >
          <BrButton
            onClick={handleRemoteSubmit}
            iconName="arrow-forward"
            hasIconLeft
            text={t('Continue')}
            className="w-full"
            dataTestId={`${dataTestPrefix}-next-btn`}
            disabled={!validationResult.isValid}
          />
        </BrTooltipWrapper>
      </BrBottomControlsWrapper>
    </BrCard>
  );
};

export default Phone;
