import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';

import PhoneForm, {
  ValidationError,
} from '@components/modules/Imtu/containers/Phone/components/PhoneForm';

import {
  BrOptionProps,
  BrSelectRenderProp,
  OptGroupProps,
} from '@root/interfaces/components/BrSelect';
import CountryCode from '@root/interfaces/CountryCode';
import { CountryAliases } from '@root/interfaces/appConfig';

import { normalizeStringCompound } from '@utils/string';

import BrCountrySelect, { BrCountrySelectRef } from '../BrCountrySelect';
import BrInput from '../BrInput';
import Flag from '../Flag';

import { useIsDesktop } from '../MediaQueryMatchers';

import { animationComplete } from '../BrDrawer';
import { onSearchByDialCodeAndAlias } from '../BrSelect/helpers';
import { getNormalizedCountryDialCode } from '../PhoneInput/helpers';

interface Props {
  countryCode: CountryCode;
  data: (BrOptionProps | OptGroupProps)[];
  countryAliases?: CountryAliases[];
  phone?: string;
  onCountryChange?: (code: CountryCode) => void;
  onSubmit?(value: string): void;
  onPhoneValidation?: (res: {
    isValid: boolean;
    errorReason?: ValidationError | undefined;
  }) => void;
  onCountryInputFocus?(): void;
  onCountryInputBlur?(): void;
}

export type ComboSelectRef = { submit(): void };

const BrComboSelect = forwardRef<ComboSelectRef, Props>((props, ref) => {
  const {
    countryCode,
    data,
    onCountryChange,
    countryAliases,
    phone,
    onSubmit,
    onPhoneValidation,
    onCountryInputFocus,
    onCountryInputBlur,
  } = props;

  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);
  const brCountrySelectRef = useRef<BrCountrySelectRef>(null);
  const wrapperRef = useRef<HTMLDivElement>();
  const submitButtonRef = useRef<{ submit(): void }>(null);
  const inputWrapperRef = useRef<HTMLDivElement>(null);

  const [isCountryBtnClicked, setIsCountryBtnClicked] = useState(false);

  const isDesktopResolution = useIsDesktop();

  const isOpened = isCountryBtnClicked && isDesktopResolution && wrapperRef.current;

  const selectButtonAddonBefore = countryCode && (
    <Flag
      className="w-[16px] h-[16px] overflow-hidden rounded-full flex-shrink-0 mx-middle self-center"
      code={countryCode as CountryCode}
    />
  );

  const ignoredRefs = [inputWrapperRef];

  useImperativeHandle(ref, () => {
    return {
      submit: submitButtonRef.current ? submitButtonRef.current.submit : () => {},
    };
  });

  const wrapperRefCallback = async (node: HTMLDivElement | null) => {
    if (node) {
      wrapperRef.current = node;
      await animationComplete(node);
      if (!isCountryBtnClicked) {
        inputRef.current?.blur();
      }
    }
  };

  const phoneCode = t(getNormalizedCountryDialCode(countryCode));

  const handleCountryChange = async (code: CountryCode) => {
    if (isDesktopResolution && brCountrySelectRef.current?.dropdownRef) {
      await animationComplete(brCountrySelectRef.current?.dropdownRef);
    } else if (brCountrySelectRef.current?.drawerRef) {
      await animationComplete(brCountrySelectRef.current?.drawerRef);
    }
    onCountryChange?.(code as CountryCode);
    setIsCountryBtnClicked(false);
  };

  const handleOnSearch = (option: BrOptionProps, searchQuery: string) => {
    return onSearchByDialCodeAndAlias(option, searchQuery, countryAliases);
  };

  const handleOnClickOutside = async () => {
    if (isDesktopResolution && brCountrySelectRef.current?.dropdownRef) {
      await animationComplete(brCountrySelectRef.current?.dropdownRef);
    } else if (brCountrySelectRef.current?.drawerRef) {
      await animationComplete(brCountrySelectRef.current?.drawerRef);
    }
    setIsCountryBtnClicked(false);
  };

  const renderCustomSelectBtnInput: BrSelectRenderProp = (params) => {
    const { isOpened: isOpen, onClick, searchValue, onSearch } = params;

    const handleOnClick = async () => {
      if (isOpen) {
        await onClick();
        if (isDesktopResolution) {
          if (brCountrySelectRef.current?.dropdownRef) {
            await animationComplete(brCountrySelectRef.current?.dropdownRef);
          }
        } else if (brCountrySelectRef.current?.drawerRef) {
          await animationComplete(brCountrySelectRef.current?.drawerRef);
        }
      }
      if (isDesktopResolution) {
        inputRef.current?.focus();
      } else {
        inputRef.current?.blur();
      }
      flushSync(() => {
        setIsCountryBtnClicked((s) => !s);
      });
      if (isDesktopResolution) {
        if (wrapperRef.current) {
          await animationComplete(wrapperRef.current);
        }
      }
      if (!isOpen) {
        onClick();
      }
    };

    return (
      <div ref={inputWrapperRef}>
        <BrInput
          iconRight={searchValue && isOpened ? 'CloseXSolid' : 'chevron-down-outline'}
          ref={inputRef}
          addonBefore={selectButtonAddonBefore}
          onWhat="surface"
          value={searchValue}
          onChange={onSearch}
          className="[&_input]:!px-0 [&_input]:indent-middle"
          placeholder={t('Enter a country')}
          onClick={handleOnClick}
          onFocus={onCountryInputFocus}
          onBlur={onCountryInputBlur}
        />
      </div>
    );
  };

  const wrapperClassNames = normalizeStringCompound([
    'grid mb-middle [grid-template-columns:88px_1fr] [transition:grid-template-columns_0.25s_ease-out]',
  ]);

  const wrapperStyles = {
    gridTemplateColumns: `${wrapperRef.current?.clientWidth}px 1fr`,
  };

  const wrapperProps = {
    ref: wrapperRefCallback,
    className: wrapperClassNames,
    style: isOpened ? wrapperStyles : undefined,
  };

  return (
    <div {...wrapperProps}>
      <BrCountrySelect
        ref={brCountrySelectRef}
        data={data}
        hasSearch
        value={countryCode}
        onWhat="surface"
        onChange={handleCountryChange}
        onSearch={handleOnSearch}
        placeholder={t('Enter a country')}
        ignoredElemsRefs={ignoredRefs}
        onClickOutside={handleOnClickOutside}
        drawerTitleText={t("Select recipient's country")}
      >
        {renderCustomSelectBtnInput}
      </BrCountrySelect>

      <div className="min-w-0 overflow-hidden">
        <PhoneForm
          phone={phone || phoneCode}
          ref={submitButtonRef}
          countryCode={countryCode}
          onSubmit={onSubmit}
          className="pl-small min-w-0"
          onValidation={onPhoneValidation}
        />
      </div>
    </div>
  );
});

export default BrComboSelect;
