import { getMonth, getYear } from 'date-fns';

import { dtcbProfileAxios } from '@services/axios';

import {
  PaymentCard,
  AddPaymentCardRequest,
  UpdatePaymentCardRequest,
  PaymentCardIssuer,
  PaymentCardConfigData,
  UpdatePaymentCardWithouBillingRequest,
  AddPaymentCardWithoutBillingRequest,
} from '@root/interfaces/contract/PaymentCards';
import { TokenizerAction } from '@root/interfaces/contract/Tokenizer';

import { PaymentCardIssuerKeys } from '@root/interfaces/PaymentCard';

import { PAYMENT_CARD_ERROR_MSG } from '@root/constants/errorMessages';

import PaymentCardClass from '@root/models/PaymentCardClass';
import PaymentCardConfig from '@root/models/PaymentCardConfig';

import { normalizeCardNumber } from '@helpers/paymentCards';

import { generateToken } from './tokenizer';

import { DTC_PROFILE_ENDPOINTS } from './constants/dtc';

export const fetchPaymentCardsConfig = async () => {
  const res = await dtcbProfileAxios.get<PaymentCardConfigData>(
    `${DTC_PROFILE_ENDPOINTS.PAYMENT_CARDS_CONFIG}`,
  );

  return new PaymentCardConfig(res.data);
};

export const fetchPaymentCardByHandleId = async (handleId: string) => {
  const res = await dtcbProfileAxios.get<PaymentCard>(
    `${DTC_PROFILE_ENDPOINTS.PAYMENT_CARDS}/${handleId}`,
  );

  return new PaymentCardClass(res.data);
};

export const fetchPaymentCards = async () => {
  const res = await dtcbProfileAxios.get<PaymentCard[]>(
    `${DTC_PROFILE_ENDPOINTS.PAYMENT_CARDS}`,
  );

  const cards = await Promise.all(
    res.data.map((item) => fetchPaymentCardByHandleId(item.handle_id)),
  );

  return cards;
};

/* eslint-disable @typescript-eslint/indent */
export const updatePaymentCardByHandleId = async (
  params: UpdatePaymentCardRequest | UpdatePaymentCardWithouBillingRequest,
) => {
  const res = await dtcbProfileAxios.put<PaymentCard>(
    `${DTC_PROFILE_ENDPOINTS.PAYMENT_CARDS}/${params.handleId}`,
    'cvv' in params
      ? {
          exp_month: getMonth(params.expDate) + 1,
          exp_year: getYear(params.expDate),
          cvv: params.cvv,
          postal_code: params.zip,
        }
      : {
          city: params.city,
          exp_month: getMonth(params.expDate) + 1,
          exp_year: getYear(params.expDate),
          holder_name: params.holderName,
          postal_code: params.zip,
          province: params.state.toUpperCase(),
          street_address_1: params.street1,
          street_address_2: params.street2,
        },
  );

  return new PaymentCardClass(res.data);
};
/* eslint-disable @typescript-eslint/indent */

export const addPaymentCard = async (
  params: AddPaymentCardRequest | AddPaymentCardWithoutBillingRequest,
) => {
  const brand: { [key in PaymentCardIssuerKeys]: PaymentCardIssuer } = {
    V: 'visa',
    MC: 'mastercard',
    AE: 'amex',
    D: 'discover',
  };

  const tokenizedCardNumber = await generateToken(
    normalizeCardNumber(params.cardNumber),
    TokenizerAction.PaymentCard,
  );

  if (tokenizedCardNumber) {
    const res = await dtcbProfileAxios.post<PaymentCard>(
      `${DTC_PROFILE_ENDPOINTS.PAYMENT_CARDS}`,
      'cvv' in params
        ? {
            brand: brand[params.issuer],
            exp_month: getMonth(params.expDate) + 1,
            exp_year: getYear(params.expDate),
            holder_name: null,
            cvv: params.cvv,
            postal_code: params.zip,
            street_address_1: null,
            street_address_2: null,
            tokenized_card_number: tokenizedCardNumber,
          }
        : {
            brand: brand[params.issuer],
            city: params.city,
            exp_month: getMonth(params.expDate) + 1,
            exp_year: getYear(params.expDate),
            holder_name: params.holderName,
            postal_code: params.zip,
            province: params?.state?.toUpperCase(),
            street_address_1: params.street1,
            street_address_2: params.street2,
            tokenized_card_number: tokenizedCardNumber,
          },
    );

    return new PaymentCardClass(res.data);
  }

  throw Error(PAYMENT_CARD_ERROR_MSG.TOKENIZER_SERVICE_ERROR);
};

export const removePaymentCardByHandleId = async (handleId: string) => {
  await dtcbProfileAxios.delete(`${DTC_PROFILE_ENDPOINTS.PAYMENT_CARDS}/${handleId}`);
};
