import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import SessionClass from '@root/models/SessionClass';

// TODO: this middleware should resolve issues but seems just brings more mess and complexity
import storageInterface from '@services/storage';
import { liveChatLogout } from '@services/liveChat';
import { repeatCall } from '@services/axios/helpers';

import { DTC_AUTH_ENDPOINTS } from '../api/v1/constants/dtc';
import { deleteSession, deleteUserAccount, refreshSession } from '../api/v1/auth';
import { firebaseAuthAPI } from '../firebase';

import { SESSION_STORE_KEY } from './constants';

export const getSessionDataFromStorage = () => {
  const sessionString = storageInterface.getItem(SESSION_STORE_KEY);

  if (sessionString) {
    return JSON.parse(sessionString) as SessionClass;
  }

  return null;
};

export const saveSessionDataToStorage = (data: SessionClass) => {
  storageInterface.setItem(
    SESSION_STORE_KEY,
    JSON.stringify({
      ...data,
    }),
  );
};

export const getIsLoggedInStatusFromStorage = (): boolean => {
  const session = getSessionDataFromStorage();
  const isUserLoggedIn = Boolean(session) && !session?.shouldVerifyEmail;
  return isUserLoggedIn;
};

// TODO: implement proper mechanism when it gets stabilized on back-end side
export const refreshUserSession = async () => {
  const session = getSessionDataFromStorage();

  if (session) {
    const res = await refreshSession({
      profileId: session.profileId,
      refreshToken: session.refreshToken,
    });

    saveSessionDataToStorage(res);
  }
};

export const logOut = async () => {
  try {
    // for some reason firebase.signOut throws "invisible" error on logout with expired session
    await Promise.allSettled([
      firebaseAuthAPI.signOut(),
      storageInterface.getItem(SESSION_STORE_KEY) ? deleteSession() : undefined,
    ]);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Error on logout', err);
  } finally {
    storageInterface.removeItem(SESSION_STORE_KEY);
    liveChatLogout();
  }
};

export const deleteUserAccountAndLogout = async (accountId: string) => {
  await deleteUserAccount(accountId);
  await logOut();
};

// looks more like helper though but I didn't find a better place to put it
export const handleExpiredSession = async ({
  axiosInstance,
  error,
}: {
  axiosInstance: AxiosInstance;
  error: AxiosError<AxiosResponse>;
}) => {
  const session = getSessionDataFromStorage();

  const isSessionRefreshError =
    error.config.url === `${DTC_AUTH_ENDPOINTS.REFRESH}/session`;

  const onAfterLogout = () => {
    window.location.reload();
  };

  if (session?.refreshToken && !isSessionRefreshError) {
    try {
      await refreshUserSession();
    } catch (err) {
      logOut().then(onAfterLogout);
      return Promise.reject();
    }
    return repeatCall(axiosInstance, error.config);
  }

  logOut().then(onAfterLogout);

  return Promise.reject();
};
