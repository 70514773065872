import BrBadge from '@components/common/BrBadge';
import BrContacts from '@components/common/BrContacts';
import { CarrierInfo } from '@components/common/BrListItem';
import { imtuProductSubTypeTextMap } from '@components/modules/Imtu/constants';

import CountryCode from '@root/interfaces/CountryCode';
import { ProductSubtype } from '@root/interfaces/contract/dtcImtuK2/ImtuK2Transaction';

interface Props {
  phone: string;
  priceText: string;
  carrierCode: string;
  countryCode: CountryCode;
  subType: ProductSubtype;
}

const ReceiptHeader = (props: Props) => {
  const { countryCode, phone, subType, carrierCode, priceText } = props;

  return (
    <div className="flex flex-col items-center space-y-middle">
      <BrContacts cmpSize="large" cmpType="flag" countryCode={countryCode} name={phone} />
      <div className="flex flex-col items-center space-y-middle">
        <BrBadge
          text={imtuProductSubTypeTextMap[subType]}
          classType="neutral"
          styleType="less-contrast"
        />
        <CarrierInfo
          carrierCode={carrierCode}
          countryCode={countryCode}
          size="xSmall"
          name={priceText}
        />
      </div>
    </div>
  );
};

export default ReceiptHeader;
