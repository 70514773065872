import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import BrCard from '@components/common/BrCard';
import BrOfferCard from '@components/common/BrOfferCard';
import Icon from '@components/common/Icon';
import BrAlert from '@components/common/BrAlert';
import BrSelectInput from '@components/common/BrPaymentCardSelect/BrSelectInput';
import BrDetails from '@components/common/BrDetails';
import BrPriceInfo, { ExtraField } from '@components/common/BrPriceInfo';
import { getFullCardTypeName } from '@components/common/BrPaymentCardSelect/helpers';
import BrStepper from '@components/common/BrStepper';

import ImtuTransaction from '@root/models/ImtuTransaction';
import PaymentCardClass from '@root/models/PaymentCardClass';

import { formatPhone } from '@helpers/phone';

import { ImtuStatus } from '@root/interfaces/MobileTopUp';

import RecipientCard from '../ImtuTxnInfoSection/RecipientCard';
import { planSubTypeMap } from '../ImtuTxnInfoSection';

interface Props {
  txn: ImtuTransaction;
  paymentCard?: PaymentCardClass;
  onCopyTxnIdClick?(): void;
  status?: ImtuStatus;
}

const ImtuTxnDetails = (props: Props) => {
  const { txn, paymentCard, onCopyTxnIdClick, status } = props;

  const { t } = useTranslation();

  /* eslint-disable @typescript-eslint/indent */
  const carrierInfo = txn.carrier
    ? {
        name: txn.carrier.name,
        code: txn.carrier.code,
      }
    : undefined;
  /* eslint-disable @typescript-eslint/indent */

  return (
    <div className="space-y-middle">
      <BrCard>
        <RecipientCard
          countryCode={txn.recipientCountryCode}
          recipientCfg={{
            badgeText: txn.recipientPhoneNumber ? t('Recipient') : undefined,
            middleText: formatPhone(txn.recipientPhoneNumber),
          }}
          carrierCfg={carrierInfo}
        />
      </BrCard>

      {txn.product && (
        <BrOfferCard
          id={txn.id}
          planTypeText={t(planSubTypeMap[txn.product?.subType])}
          promoBadgeText={txn.product.carrierPromo?.title}
          title={
            txn.product.subType === 'Mobile Top Up'
              ? txn.product.recipientAmountText
              : txn.product.name
          }
          descriptionShort={
            txn.product.benefitsCfg?.termAmountDays
              ? t(`Valid for {{number}} days`, {
                  number: txn.product.benefitsCfg.termAmountDays,
                })
              : undefined
          }
        >
          {txn.product.carrierPromo?.description || txn.product.description ? (
            <>
              {txn.product.carrierPromo?.description ? (
                <BrAlert
                  header={t('Promotion details')}
                  icon="egift-solid"
                  text={txn.product.carrierPromo?.description.toUpperCase()}
                  classType="warning-variant"
                  styleType="with-header"
                  className="mb-middle !whitespace-normal w-full"
                />
              ) : null}
              {txn.product.description ? (
                <div className="space-y-small">
                  <div className="flex space-x-xsmall">
                    <Icon name="product-fill" width={14} height={14} />
                    <div className="text-body/callout/default">
                      {t('Product Details')}
                    </div>
                  </div>
                  <div className="whitespace-break-spaces text-body/callout/default text-on-colors/on-surface">
                    {txn.product.description}
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
        </BrOfferCard>
      )}
      <BrCard variant="default">
        <BrDetails
          config={[
            {
              type: 'default',
              label: t('Confirmation number'),
              iconName: 'copy-mix',
              text: txn.id,
              shouldShowButton: true,
              onActionBtnClick: onCopyTxnIdClick,
            },
            {
              type: 'bold-icon-with-bg',
              label: t('Other details'),
              text: format(txn.submitDate, 'MMMM dd, yyyy'),
            },
          ]}
        />
      </BrCard>
      {txn.product && (
        <BrCard>
          <BrPriceInfo
            extraFields={
              txn.product?.fee !== undefined
                ? [
                    {
                      title: t('Subtotal'),
                      valueText: txn.product.senderBaseAmountText,
                    },
                    { title: t('Service Fee'), valueText: txn.product.feeText || '' },
                    txn.product.discount
                      ? {
                          title: t('Discount'),
                          valueText: `- ${txn.product.discount.amount} ${txn.product.discount.currency}`,
                          type: 'discount',
                        }
                      : undefined,
                  ].filter((item): item is ExtraField => {
                    return Boolean(item);
                  })
                : undefined
            }
            isExpandedDefault
            totalText={txn.product?.senderTotalAmountText}
          />
        </BrCard>
      )}
      {paymentCard && (
        <BrSelectInput
          cardType={getFullCardTypeName(paymentCard.type)}
          maskedNumber={`**** ${paymentCard.maskedNumber.slice(-4).toUpperCase()}`}
          paySource={paymentCard.paySource!}
        />
      )}
      {paymentCard && carrierInfo?.name && (
        <BrStepper
          className="!mt-xlarge"
          config={[
            {
              state: 'Success',
              title: t('Submitted'),
              text: t(`{{submittedDate}} at {{submittedTime}}`, {
                submittedDate: format(txn.submitDate, 'MMMM dd, yyyy'),
                submittedTime: format(txn.submitDate, "h:mm aaaaa'm'"),
              }),
            },
            {
              state: 'Success',
              title: t('Payment'),
              text: `${getFullCardTypeName(
                paymentCard.type,
              )} **** ${paymentCard.maskedNumber.slice(-4).toUpperCase()}`,
            },
            {
              state: status === 'queued' ? 'Queued' : 'Success',
              title: status === 'queued' ? t('Queued') : t('Sending to carrier'),
              text:
                status === 'queued'
                  ? t(
                      'Transaction queued. We are on it!\nPlease check transaction history.',
                    )
                  : carrierInfo.name,
            },

            {
              state: status === 'queued' ? 'Disabled' : 'Success',
              title: t('Completed'),
            },
          ]}
        />
      )}
    </div>
  );
};

export default ImtuTxnDetails;
