import React, { memo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import MainPage from '@components/pages/MainPage';

import TransactionsHistoryPage from '@components/pages/TransactionsHistoryPage';

import UserAccountPage from '@components/pages/UserAccountPage';
import UserAccountProfilePage from '@components/pages/UserAccountProfilePage';

import NotFoundPage from '@components/pages/NotFoundPage';

import ImtuPage from '@components/pages/ImtuPage';
import ImtuReceiptPage from '@components/pages/ImtuReceiptPage';

import LoginPage from '@components/pages/LoginPage';

import CallingPage from '@components/pages/CallingPage';
import CallingRechargeReceiptPage from '@components/pages/CallingRechargeReceipt';
import CallingAutoRechargePage from '@components/pages/CallingAutoRechargePage';

import CallingPlansPage from '@components/pages/CallingPlansPage';
import CallingPlansStatusPage from '@components/pages/CallingPlansStatusPage';
import CallingPlansReceiptPage from '@components/pages/CallingPlanReceiptPage';
import CallingPlansCancelSubSuccessPage from '@components/pages/CallingPlansCancelSubSuccessPage';
import ImtuCompletedOrderPage from '@components/pages/ImtuCompletedOrderPage';
import ImtuTxnDetailsPage from '@components/pages/ImtuTxnDetailsPage';

import PrivateRoute from './PrivateRoute';
import LoginRoute from './LoginRoute';

import PATHS from './paths';

/* eslint-disable */
const Router = () => (
  <Switch>
    <Route        path={PATHS.BASE}                             exact       component={MainPage} />

    <LoginRoute   path={PATHS.LOGIN}                                        component={LoginPage} />

    <PrivateRoute path={PATHS.ACCOUNT}                          exact       component={UserAccountPage} />
    <PrivateRoute path={PATHS.ACCOUNT_PROFILE}                  exact       component={UserAccountProfilePage} />

    <PrivateRoute path={PATHS.TRANSACTIONS_HISTORY}             exact       component={TransactionsHistoryPage} />

    <PrivateRoute path={PATHS.IMTU}                                         component={ImtuPage} />
    <PrivateRoute path={`${PATHS.IMTU_COMPLETED_ORDER}/:id`}                component={ImtuCompletedOrderPage} />
    <PrivateRoute path={`${PATHS.IMTU_RECEIPT}/:id`}                        component={ImtuReceiptPage} />
    <PrivateRoute path={`${PATHS.IMTU_TXN_DETAILS}/:id`}                    component={ImtuTxnDetailsPage} />

    <PrivateRoute path={PATHS.CALLING}                                      component={CallingPage} />
    <PrivateRoute path={PATHS.CALLING_AUTO_RECHARGE}                        component={CallingAutoRechargePage} />
    <PrivateRoute path={`${PATHS.CALLING_RECHARGE_RECEIPT}/:id`}            component={CallingRechargeReceiptPage} />

    <PrivateRoute path={PATHS.CALLING_PLANS}                                component={CallingPlansPage} />
    <PrivateRoute path={`${PATHS.CALLING_PLAN_RECEIPT}/:id`}                component={CallingPlansReceiptPage} />
    <PrivateRoute path={`${PATHS.CALLING_PLAN_COMPLETED_ORDER}/:id`}        component={CallingPlansStatusPage} />
    <PrivateRoute path={`${PATHS.CALLING_PLAN_CANCEL_SUB_SUCCESS}/:id`}     component={CallingPlansCancelSubSuccessPage} />

    <Redirect     path={PATHS.SEND_MONEY}                                   to={PATHS.BASE} />

    <Route        path="*"                                                  component={NotFoundPage} />
  </Switch>
);
/* eslint-enable */

export default memo(Router);
